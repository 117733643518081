<template>
  <div
    class="level-track__wrapper"
    :class="before ? 'level-track__before' : 'level-track__after'"
  >
    <div
      class="level-track-line"
      :class="firstLineStyle"
    />
    <TTTooltip
      bottom
      max-width="304"
      content-class="tt-text-body-2 text-center level-track__tooltip"
      :disabled="!stateText"
    >
      <template #activator="{ on, attrs }">
        <TTBtn
          v-if="lockShow"
          fab
          width="24"
          height="24"
          color="tt-light-mono-24"
          class="level-track-line-lock"
          v-bind="attrs"
          data-test-label="track-line-btn-lock"
          v-on="on"
        >
          <VIcon color="tt-light-mono-0">
            fa fa-lock-alt
          </VIcon>
        </TTBtn>
      </template>
      <span>{{ stateText }}</span>
    </TTTooltip>
    <div
      class="level-track-line"
      :class="secondLineStyle"
    />
  </div>
</template>

<script>
import { CLOSED } from '@/helpers/constants';

export default {
  name: 'TrackLine',
  props: {
    currentState: {
      type: String,
      default: null,
    },
    prevState: {
      type: String,
      default: null,
    },
    nextState: {
      type: String,
      default: null,
    },
    before: {
      type: Boolean,
      default: false,
    },
    stateText: {
      type: String,
      default: null,
    },
  },
  computed: {
    lockShow() {
      if (this.before) {
        return this.currentState === CLOSED && this.prevState !== CLOSED;
      }
      return this.currentState !== CLOSED && this.nextState === CLOSED;
    },
    firstLineStyle() {
      return { 'level-track-line-dashed': this.currentState === CLOSED };
    },
    secondLineStyle() {
      return {
        'level-track-line-dashed':
          this.before
            ? this.prevState === CLOSED
            : this.nextState === CLOSED,
      };
    },
  },
};
</script>
<style lang="scss">
$tt-light-mono-100_08: rgba(map-get($tt-light-mono-100, 'base'), 0.8);
.level-track__tooltip {
  background-color: $tt-light-mono-100_08;
  padding: 12px !important;
  opacity: 1 !important;
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: -6px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $tt-light-mono-100_08;
    transform: translate(-50%, 0%);
  }
}
</style>
<style lang="scss" scoped>
.level-track__wrapper{
  position: relative;
  display: flex;
  height: 100px;
  padding-left: 64px;
  width: calc(50% + 72px);
  .level-track-line-lock{
    align-self: center;
  }
  .level-track-line{
    display: flex;
    flex: 1 1 auto;
    border: 2px solid #C4C6C8;
    height: 51px;
  }
  .level-track-line-dashed{
    border-style: dashed;
  }
  &.level-track__before {
    .level-track-line:first-child {
      align-self: flex-end;
      border-top-left-radius: 50px;
      border-bottom: 0;
      border-right: 0;
    }

    .level-track-line:last-child {
      border-bottom-right-radius: 50px;
      border-top: 0;
      border-left: 0;
    }
  }
  &.level-track__after {
    .level-track-line:first-child {
      border-bottom-left-radius: 50px;
      border-top: 0;
      border-right: 0;
    }

    .level-track-line:last-child {
      align-self: flex-end;
      border-top-right-radius: 50px;
      border-bottom: 0;
      border-left: 0;
    }
  }
}
</style>
