<template>
  <VCard
    class="level-card pa-4"
    :class="cardClasses"
    elevation="0"
    :to="isClosed ? {} :{name : Names.R_APP_LEVEL_VIEW, params : {levelId : String(level.id)}}"
    :ripple="false"
  >
    <LevelCard
      :icon-url="level.iconUrl"
      :name="level.name"
      :state="level.state"
      :task-completed="level.taskCompleted"
      :task-count="level.taskCount"
      is-track
    >
      <template #chips>
        <ChipDuration
          data-test="level-card-duration"
          :duration="chips.duration"
        />
        <ChipBonus
          class="ml-2"
          :current="chips.current"
          :max="chips.max"
          data-test="level-card-bonus"
        />
      </template>
    </LevelCard>
  </VCard>
</template>

<script>
import { CLOSED } from '@/helpers/constants';
import ChipDuration from '@/components/chips/ChipDuration.vue';
import ChipBonus from '@/components/chips/ChipBonus.vue';
import LevelCard from '@/components/level/LevelCard.vue';

export default {
  name: 'TrackCard',

  components: { LevelCard, ChipBonus, ChipDuration },

  inject: ['Names'],

  props: {
    /** @typedef {Object} level
     * @property {Object} details
     * @property {Boolean} forTutor
     * @property {String} iconUrl
     * @property {Number} id
     * @property {Number} levelN
     * @property {String} name
     * @property {String} state
     * @property {Number} taskCompleted
     * @property {Number} taskCount
     * @property {Task[]} tasks
     * @property {Number} tasksToNext
     */

    /** @typedef Task
     * @property {Number} bonus
     * @property {Array} content
     * @property {String} description
     * @property {Number} durationMin
     * @property {String} entityType
     * @property {Number} id
     * @property {Boolean} isChecked
     * @property {Boolean} isCompleted
     * @property {Boolean} isRequired
     * @property {Number} maxBonus
     * @property {String} name
     * @property {String} type
     */
    level: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isClosed() {
      return this.level.state === CLOSED;
    },
    cardClasses() {
      return {
        'level-card__active': !this.isClosed,
        'tt-light-mono-46--text': this.isClosed,
        'level-card__disabled': this.isClosed,
      };
    },
    chips() {
      if (!this.level?.tasks?.length) {
        return {
          duration: 0,
          current: 0,
          max: 0,
        };
      }
      return this.level.tasks.reduce(
        ({ current, max, duration }, { bonus, maxBonus, durationMin }) => (
          { duration: duration + durationMin, current: current + bonus, max: max + maxBonus }
        ),
        {
          duration: 0,
          current: 0,
          max: 0,
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.level-card:focus:before{
  opacity: 0!important;
}
.level-card{
  border-radius: 16px!important;
  z-index: 1;
  min-width: 0;
}
.level-card__active{
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08)!important;
  transition: 1s;
}
.level-card__active:hover{
  transform: scale(1.02);
}
.level-card__disabled{
  border: 2px solid #EBECED;
  pointer-events: none;
  user-select: none;
}
</style>
